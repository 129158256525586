/* ProgressWheel.css */

.relative {
    position: relative;
  }
  
  .indeterminate .progress-text::before {
    content: "Loading...";
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    font-size: 0.8rem; /* Adjust font size as needed */
    color: teal; /* Set your desired color for indeterminate state */
  }
  
  .progress-background {
    box-sizing: border-box;
  }
  
  .progress-foreground {
    box-sizing: border-box;
    border-style: solid;
    border-color: teal; /* Set your desired color for progress */
  }
  
  .progress-text {
    box-sizing: border-box;
    color: teal; /* Set your desired color for percentage text */
    font-weight: bold;
  }
  
  /* Add any additional styling as needed */
  

  .toggle-btn {
    width: 40px;
    height: 25px;
    background-color: var(--text-color);
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 50px;
    padding: 5px;
    position: relative;
    transform: scale(1.5);
    cursor: pointer;
  }

  .ball {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: var(--background);
    position: absolute;
    top: 2;
    left: 2;
    transition: all 0.2s linear;
  }
  
  .move {
    transform: translateX(22px);
  }



  /* LOADING INDICATOR TEXT ANIMATIONS */

  @keyframes slideUp {
    0% {
      opacity: 0;
      transform: translateY(20px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  @keyframes slideDown {
    0% {
      opacity: 1;
      transform: translateY(0);
    }
    100% {
      opacity: 0;
      transform: translateY(-20px);
    }
  }
  
  .text-slide-up {
    animation: slideUp 0.5s forwards;
  }
  
  .text-slide-down {
    animation: slideDown 0.5s forwards;
  }