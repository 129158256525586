.vertical-menu {
  background-color: #273241;
  color: white;
  top: 0;
  display: flex;
  flex-direction: column;
  position: sticky;
  z-index: 900;
}


/* Truncate long email addresses with ellipsis */
.email-truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 150px;
  /* Adjust the width as needed */
}