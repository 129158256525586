@import '~react-toastify/dist/ReactToastify.min.css';
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

/*
    BRAND COLORS
    DARK BLUE: #3d6dff
    Ligher Blue: #1fb1fe
*/

:root {
  --font-size-sm: 12px;
  --font-size-md: 16px;
  --font-size-lg: 20px;

  /*
    Variables for Light Theme
  */
  --background: #f4f4f4;
  --primary: #3d6dff;
  --special-background: #ffffff;
  --text-color: #555555;
  --special-text-color: #000000;
  --white-black: #ffffff;
  --white-black-background-opacity: rgba(0, 0, 0, 0.5);
  --shadow-lg: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
  --shadow-md: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 6px 6px -2px rgba(0, 0, 0, 0.04);
  --shadow-sm: 0 5px 10px 0 rgba(0, 0, 0, 0.1), 0 2px 4px 0 rgba(0, 0, 0, 0.04);
  --social-cards-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.03) 0px 4px 6px -2px;
}




/* Dark Mode Color Scheme */
[data-theme="dark"] {
  --white-black-background-opacity: rgba(0, 0, 0, 0.5);
  --background: #12121c;
  --primary: #3d6dff;
  --special-background: #20283E;
  --text-color: #eee;
  --white-black: #000000;
  --special-text-color: #eee;
}

.--social-cards-shadow {
  box-shadow: var(--social-cards-shadow);
}


.--dark-theme-color {
  color: white;
  background-color: #1e1e2f !important;
}

.--light-theme-color {
  color: black;
  background-color: #f4f4f4;
}

.--theme-colors {
  color: var(--special-text-color) !important;
  background-color: var(--special-background) !important;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

html {
  font-size: 15px;
}

body,
.--font-family {
  font-family: "Poppins", sans-serif;
}

/**
font-family: "Poppins", sans-serif !important;

  MY OWN CUSTOM CSS FOR THIS APPLICATION
*/

.pages {
  min-height: 100vh;
  background-color: var(--background);
  color: var(--text-color);
  font-size: var(--font-size-md);
}

.active,
.active .navbar-icon {
  color: #2AB7CA !important;
  font-weight: 600 !important;
  background-color: rgb(55 65 81);
}

.medium-checkbox {
  appearance: none;
  width: 20px;
  height: 20px;
  border: 2px solid #333;
  border-radius: 4px;
  margin-right: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Style for the checked state */
.medium-checkbox:checked {
  background-color: #333;
  border: 2px solid #333;
}

/* Custom checkbox indicator (checkmark) */
.medium-checkbox:checked::before {
  content: "\2713";
  /* Unicode checkmark symbol */
  color: #fff;
  position: relative;
  top: 0;
  left: 0;
}

.--success-message {
  font-size: var(--font-size-md);
  color: green !important;
  font-weight: 400;
  padding: 5px 0;
}

.--error-message {
  font-size: var(--font-size-sm);
  color: red !important;
  font-weight: 400;
  padding: 5px 0;
  overflow-wrap: break-word;
  word-wrap: break-word;
  white-space: normal;
  text-align: center;
}

.--text-color {
  color: var(--white-black);
}

/*
  DASHBOARD
*/


.dashboard-container {
  min-width: 95%;
  margin: 0 auto;
  padding: 10px 20px;
}

.dashboard-container .heading {
  font-size: 1.3rem;
}

.apexcharts-yaxis-label,
.apexcharts-xaxis-label,
.apexcharts-legend-text {
  fill: var(--text-color) !important;
  color: var(--text-color) !important;
}

.dashboard-section {
  color: var(--text-color);
  background-color: var(--special-background);
  padding: 15px;
  max-width: 100%;
  border-radius: 0.3rem;
  border-width: 1px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, .1), 0 1px 2px -1px rgba(0, 0, 0, .2);
}

.nav-link {
  color: white !important;
}




/* For WebKit browsers */
::-webkit-scrollbar {
  width: 5px;
  /* Set the width of the scrollbar */
  height: 5px;
  direction: ltr;
}

::-webkit-scrollbar-thumb {
  background-color: gray;
  /* Set the color of the thumb */
  border-radius: 5px;
  /* Set the border-radius of the thumb */
}

::-webkit-scrollbar-track {
  background-color: #f1f1f1;
  /* Set the color of the track */
}

/* Basic elements styling - automatically adapts to theme */
h1, h2, h3, h4, h5, h6 {
  color: var(--special-text-color)!important;
  margin-bottom: 0.5em !important; 
}

h1 {
  font-size: 2rem !important;
  font-weight: 700 !important;
}

h2 {
  font-size: 1.75rem !important;
  font-weight: 600 !important;
}

h3 {
  font-size: 1.5rem !important;
  font-weight: 600 !important;
}

h4 {
  font-size: 1.25rem !important;
  font-weight: 500 !important;
}

h5 {
  font-size: 1.1rem !important;
  font-weight: 500 !important;
}

h6 {
  font-size: 1rem !important;
  font-weight: 500 !important;
}

p, span, div, li, td, th, label {
  color: var(--text-color);
}


/* Theme utility classes */
.text-primary {
  color: var(--primary) !important;
}

.text-default {
  color: var(--text-color) !important;
}

.text-special {
  color: var(--special-text-color) !important;
}

.bg-primary {
  background-color: var(--primary) !important;
}

.bg-default {
  background-color: var(--background) !important;
}

.bg-special {
  background-color: var(--special-background) !important;
}